import React, { Component } from "react";
const TrustBox = ({ trustBoxRef }) => (
  <div
    ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
    className="trustpilot-widget" // Renamed this to className.
    // [ long list of data attributes...]
    data-locale="en-GB"
    data-template-id="53aa8807dec7e10d38f59f32"
    data-businessunit-id="5aa140cc09612200012fb73b"
    data-style-height="120px"
    data-style-width="200px"
    data-theme="light"
    data-stars="1,2,3,4,5"
  >
    <a
      href="https://www.trustpilot.com/review/example.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      Trustpilot
    </a>
  </div>
);
class TrustBoxContainer extends Component {
  constructor(props) {
    super(props);
    this.trustBoxRef = React.createRef();
  }
  componentDidMount() {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
    }
  }
  render() {
    return <TrustBox trustBoxRef={this.trustBoxRef} />;
  }
}
export default TrustBoxContainer;
